import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { DataTable } from 'primereact/datatable';
import { Row } from 'primereact/row';
import React from 'react';
import { useSelector } from 'react-redux';

import { Messages } from '@Config/messages';
import { useTranslations } from '@Hooks/useTranslations';
import { getCurrentLanguage } from '@Store/localization/localization.selector';
import { languageBasedDate } from '@Utils/dates';
import { convertStatus, decideStatus } from '@Utils/helpers';

import styles from './AutomatedReportsTable.scss';

export interface AutomatedReportsTableProps {
  data: any;
}

const AutomatedReportsTable: React.FunctionComponent<AutomatedReportsTableProps> = ({ data }) => {
  const currentLanguage = useSelector(getCurrentLanguage);
  const dt = React.useRef<DataTable>(null);
  const t = useTranslations();

  const initialData =
    data &&
    data.map((nd: any) => {
      return {
        dTitle: nd.deathCase.title ? nd.deathCase.title : '-',
        dName:
          nd.deathCase.firstName && nd.deathCase.lastName ? `${nd.deathCase.firstName} ${nd.deathCase.lastName}` : '-',
        dBirthDate: nd.deathCase.birthDate,
        dDeathDate: nd.deathCase.deathDate,
        dFuneralDate: nd.deathCase.funeralDate,
        dBuildingName: nd.deathCase.buildingName,
        dBuildingNumber: nd.deathCase.buildingNumber,
        dAddress: nd.deathCase.address1 ? `${nd.deathCase.address1} ${nd.deathCase.address2}` : '-',
        dCity: nd.deathCase.city,
        dPostalCode: nd.deathCase.postalCode,
        dPropertyType: nd.deathCase.propertyType,
        dOccupancyStatus: nd.deathCase.occupancyStatus,
        notifier: nd.deathCase.notifier,
        nTitle: nd.deathCase.notifier ? nd.deathCase.notifier.title : '-',
        nName: nd.deathCase.notifier ? `${nd.deathCase.notifier.firstName} ${nd.deathCase.notifier.lastName}` : '-',
        nPhone: nd.deathCase.notifier ? nd.deathCase.notifier.phone : '-',
        nEmail: nd.deathCase.notifier ? nd.deathCase.notifier.email : '-',
        status: nd.deathCaseServiceProvider.status,
        sentAt: nd.deathCaseServiceProvider.sentAt,
        notificationId: nd.deathCaseServiceProvider.notificationId,
        spName: nd.deathCaseServiceProvider.serviceProvider.name,
        accessCode: nd.deathCaseServiceProvider.accessCode,
        lastUpdatedBy: nd.deathCaseServiceProvider.lastUpdatedBy,
      };
    });

  const newData = [...initialData];
  const showTitle = newData.every((item) => item.dTitle === '-');
  const showName = newData.every((item) => item.dName === '-');
  const showBuildingName = newData.every((item) => item.dBuildingName === null);
  const showBuildingNumber = newData.every((item) => item.dBuildingNumber === '-');
  const showAddress = newData.every((item) => item.dAddress === '-');
  const showCity = newData.every((item) => item.dCity === null);
  const showPostalCode = newData.every((item) => item.dPostalCode === '-');
  const showPropertyType = newData.every((item) => item.dPropertyType === '-');
  const showOccupancyStatus = newData.every((item) => item.dOccupancyStatus === '-');
  const showBirthDate = newData.every((item) => item.dBirthDate === null);
  const showDeathDate = newData.every((item) => item.dDeathDate === null);
  const showFuneralDate = newData.every((item) => item.dFuneralDate === null);
  const showNotifier = newData.every((item) => item.notifier === null);

  const showColumnDetails = [
    showTitle,
    showName,
    showBirthDate,
    showDeathDate,
    showFuneralDate,
    showBuildingName,
    showBuildingNumber,
    showAddress,
    showCity,
    showPostalCode,
    showPropertyType,
    showOccupancyStatus,
  ];

  const visibleColumnsCount = showColumnDetails.reduce((count, column) => {
    return column ? count : count + 1;
  }, 0);

  const headerGroup = (
    <ColumnGroup>
      <Row>
        <Column header="Deceased's info" colSpan={visibleColumnsCount} />
        <Column header="Notifier Details" colSpan={4} hidden={showNotifier} />
        <Column header="Notification info" colSpan={5} />
      </Row>
      <Row>
        <Column header="Title" sortable field="dTitle" hidden={showTitle} />
        <Column header="Name" sortable field="dName" hidden={showName} />
        <Column header="Date of birth" sortable field="dBirthDate" hidden={showBirthDate} />
        <Column header="Date of death" sortable field="dDeathDate" hidden={showDeathDate} />
        <Column header="Date of funeral" sortable field="dFuneralDate" hidden={showFuneralDate} />
        <Column header="Building name" sortable field="dBuildingName" hidden={showBuildingName} />
        <Column header="Building number" sortable field="dBuildingNumber" hidden={showBuildingNumber} />
        <Column header="Address" sortable field="dAddress" hidden={showAddress} />
        <Column header="City" sortable field="dCity" hidden={showCity} />
        <Column header="Postal code" sortable field="dPostalCode" hidden={showPostalCode} />
        <Column header="Property Status" sortable field="dPropertyType" hidden={showPropertyType} />
        <Column header="Occupancy status" sortable field="dOccupancyStatus" hidden={showOccupancyStatus} />
        <Column header="Title" sortable field="nTitle" hidden={showNotifier} />
        <Column header="Name" sortable field="nName" hidden={showNotifier} />
        <Column header="Phone" sortable field="nPhone" hidden={showNotifier} />
        <Column header="Email" sortable field="nEmail" hidden={showNotifier} />
        <Column header="Status" sortable field="status" />
        <Column header="Sent At" sortable field="sentAt" />
        {/* <Column header="Notification Id" sortable field="notificationId" /> */}
        <Column header="Service provider name" sortable field="spName" />
        <Column header="Access code" sortable field="accessCode" />
        <Column header="Last updated by" sortable field="lastUpdatedBy" />
      </Row>
    </ColumnGroup>
  );

  const exportCSV = (selectionOnly: any) => {
    dt.current.exportCSV({ selectionOnly });
  };

  const header = (
    <div className={styles.flexDisplay}>
      <Button type="button" icon="pi pi-file" rounded onClick={() => exportCSV(false)} data-pr-tooltip="CSV" />
    </div>
  );

  return (
    <div className={styles.tableContainer}>
      <DataTable
        value={initialData}
        id="notificationsTable"
        stripedRows
        paginator
        ref={dt}
        header={header}
        headerColumnGroup={headerGroup}
        // scrollHeight="490px"
        emptyMessage="No records found"
        // filters={filters}
        // onFilter={handleFilter}
        // removableSort
        // sortMode="multiple"
        // multiSortMeta={[{ field: 'dueBy', order: -1 }]}
        // sortField={sortField}
        // sortOrder={sortOrder}
        scrollable
        // onSort={(e: any) => onSort(e)}
        rows={100}
        className={styles.fonts}
        // filterDisplay={cursorId === '' ? 'menu' : undefined}
        //rowsPerPageOptions={[5, 10, 25, 50]}
        resizableColumns
        // globalFilterFields={[
        //   'ref',
        //   'deathCaseName',
        //   'spName',
        //   'notifierName',
        //   'notifierType',
        //   'filterDueBy',
        //   'filterSentAt',
        //   'filterResentAt',
        //   'filterCompletedAt',
        //   'filterResponseDate',
        //   'responseDate',
        //   'status',
        //   'sentAt',
        //   'resentAt',
        //   'completedAt',
        //   'accessCode',
        //   'latestComment',
        //   'notificationChannel',
        // ]}
      >
        <Column field="dTitle" header="Title" sortable hidden={showTitle} />

        <Column field="dName" header="Name" sortable hidden={showName} />

        <Column
          field="dBirthDate"
          header="Date of birth"
          sortable
          body={(rowData: any) => {
            return <div>{languageBasedDate(rowData?.dBirthDate, currentLanguage)}</div>;
          }}
          hidden={showBirthDate}
        />

        <Column
          field="dDeathDate"
          header="Date of death"
          sortable
          body={(rowData: any) => {
            return <div>{languageBasedDate(rowData?.dDeathDate, currentLanguage)}</div>;
          }}
          hidden={showDeathDate}
        />

        <Column
          field="dFuneralDate"
          header="Date of funeral"
          sortable
          body={(rowData: any) => {
            return <div>{rowData?.dFuneralDate ? languageBasedDate(rowData?.dFuneralDate, currentLanguage) : '-'}</div>;
          }}
          hidden={showFuneralDate}
        />
        <Column header="Building name" sortable field="dBuildingName" hidden={showBuildingName} />
        <Column header="Building number" sortable field="dBuildingNumber" hidden={showBuildingNumber} />
        <Column header="Address" sortable field="dAddress" hidden={showAddress} />
        <Column header="City" sortable field="dCity" hidden={showCity} />
        <Column header="Postal code" sortable field="dPostalCode" hidden={showPostalCode} />
        <Column
          header="Property Status"
          sortable
          field="dPropertyType"
          hidden={showPropertyType}
          body={(rowData: any) => {
            return (
              <div>
                {rowData.dPropertyType
                  ? t(`${Messages.labelBaseCasePropertyPrefix}${rowData.dPropertyType.toLowerCase()}` as Messages)
                  : '-'}
              </div>
            );
          }}
        />
        <Column
          header="Occupancy status"
          sortable
          field="dOccupancyStatus"
          hidden={showOccupancyStatus}
          body={(rowData: any) => {
            return rowData.dOccupancyStatus ? (
              <div>
                {rowData.dOccupancyStatus
                  ? t(`${Messages.labelOccupancyStatusPrefix}${rowData.dOccupancyStatus.toLowerCase()}` as Messages)
                  : '-'}
              </div>
            ) : (
              <div>-</div>
            );
          }}
        />

        <Column header="Title" sortable field="nTitle" hidden={showNotifier} />
        <Column header="Name" sortable field="nName" hidden={showNotifier} />
        <Column header="Phone" sortable field="nPhone" hidden={showNotifier} />
        <Column header="Email" sortable field="nEmail" hidden={showNotifier} />

        <Column
          header="Status"
          sortable
          field="status"
          body={(rowData: any) => {
            return <div>{decideStatus(convertStatus(rowData.status)).statusTitle}</div>;
          }}
        />
        <Column
          header="Sent At"
          sortable
          field="sentAt"
          body={(rowData: any) => {
            return <div>{rowData?.sentAt ? languageBasedDate(rowData?.sentAt, currentLanguage) : '-'}</div>;
          }}
        />
        {/* <Column header="Notification Id" sortable field="notificationId" /> */}
        <Column header="Service provider name" sortable field="spName" />
        <Column header="Access code" sortable field="accessCode" />
        <Column header="Last updated by" sortable field="lastUpdatedBy" />
      </DataTable>
    </div>
  );
};

export default AutomatedReportsTable;
