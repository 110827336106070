import React, { createContext, useContext, useEffect, useState } from 'react';

import { useServiceProvidersQuery } from '@Graphql/graphqlTypes.generated';

interface ServiceProviderContextType {
  serviceProviders: any[]; // Change type as needed
  loading: boolean;
  refetchServiceProviders: () => void;
}

const ServiceProviderContext = createContext<ServiceProviderContextType | undefined>(undefined);

export const ServiceProviderProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { data, loading, refetch } = useServiceProvidersQuery({
    fetchPolicy: 'network-only',
  });

  const [serviceProviders, setServiceProviders] = useState<any[]>([]);

  useEffect(() => {
    if (data?.serviceProviders) {
      setServiceProviders(data.serviceProviders);
    }
  }, [data]);

  return (
    <ServiceProviderContext.Provider value={{ serviceProviders, loading, refetchServiceProviders: refetch }}>
      {children}
    </ServiceProviderContext.Provider>
  );
};

export const useServiceProvider = () => {
  const context = useContext(ServiceProviderContext);
  if (!context) {
    throw new Error('useServiceProvider must be used within a ServiceProviderProvider');
  }
  return context;
};
