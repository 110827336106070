import * as React from 'react';

import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import Typography from '@Components/Typography';
import { EnterKeyCode } from '@Config/constants';
import { Messages } from '@Config/messages';
import { ServiceProviderNode } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';

import styles from './SpCard.scss';

export interface SpCardProps {
  serviceProviders: ServiceProviderNode[] | undefined;
  onSelectClick: (id: string) => void;
}

const SpCard: React.FunctionComponent<SpCardProps> = ({ serviceProviders, onSelectClick }) => {
  const t = useTranslations();
  const [showMore, setShowMore] = React.useState(false);

  // **Step 1: Sort serviceProviders by priority (ascending)**
  const sortedServiceProviders = React.useMemo(() => {
    return serviceProviders ? [...serviceProviders].sort((a, b) => (a.priority || 0) - (b.priority || 0)) : [];
  }, [serviceProviders]);

  // **Step 2: Split into two halves**
  const midIndex = Math.ceil(sortedServiceProviders.length / 2);
  const firstHalf = sortedServiceProviders.slice(0, midIndex);
  const secondHalf = sortedServiceProviders.slice(midIndex);

  return (
    <div className={styles.mt8}>
      <Typography tag="p" msg={t('label_add_common_providers' as Messages)} html />
      <Row className={styles.spCardContainer}>
        {/* Show firstHalf initially, add secondHalf when showMore is true */}
        {[...firstHalf, ...(showMore ? secondHalf : [])].map((sp) => (
          <Col key={sp.name} className={styles.cardBox} onClick={() => onSelectClick(sp.id)}>
            {sp.logo ? (
              <img className={styles.logoWrapper} src={sp.logo} alt={sp.name} />
            ) : (
              <div className={styles.initialLogoContainer}>
                <span>{sp.name.charAt(0).toUpperCase()}</span>
              </div>
            )}
            {sp.name}
          </Col>
        ))}
      </Row>

      {/* Show 'Show More' button only if there are more items */}
      {!showMore && secondHalf.length > 0 && (
        <Typography
          className={styles.handSymbol}
          msg={t('link_show_more' as Messages)}
          underline
          tag="div"
          size="m"
          textCenter
          tabIndex={0}
          bold
          onClick={() => setShowMore(true)}
          onKeyDown={(e: React.KeyboardEvent) => {
            if (e.keyCode === EnterKeyCode) {
              setShowMore(true);
            }
          }}
        />
      )}
    </div>
  );
};

export default SpCard;
