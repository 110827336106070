import { ConnectedRouter } from 'connected-react-router';
import { History } from 'history';
import * as React from 'react';
import { hot } from 'react-hot-loader/root';
import { Provider } from 'react-redux';
import { Store } from 'redux';

import { AppContextProvider } from '@Config/localeContext';
import { RootState } from '@Store/reducers';
import { ServiceProviderProvider } from '@Utils/ServiceProviderContext';

import App from './App';
import CookieBanner from './CookieBanner';
import Modal from './Modal';
import Snackbar from './Snackbar';

interface RootContainerProps {
  store: Store<RootState>;
  history: History;
}

const Root: React.FunctionComponent<RootContainerProps> = ({ store, history }) => {
  const state = store.getState();

  return (
    <AppContextProvider value={state.localization.currentLanguage}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <ServiceProviderProvider>
            <Snackbar />
            <Modal />
            <CookieBanner />

            <App />
          </ServiceProviderProvider>
        </ConnectedRouter>
      </Provider>
    </AppContextProvider>
  );
};

export default hot(Root);
